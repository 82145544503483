<template>
  <b-modal
    :visible="isCheckOrderActive"
    :title="$t('Check Order')"
    ok-title="Accept"
    size="xl"
    no-close-on-backdrop
    @change="val => $emit('update:is-check-order-active', val)"
  >
    <b-overlay
      :show="dataLoading"
      rounded="sm"
    >
      <b-tabs
        content-class="pt-1"
        fill
      >
        <p
          v-if="dataResponse != null"
          class="mr-2"
        >
          {{ $t('Member') }} :
          {{ dataResponse['userInfo']['firstName'].toString() }}
          {{ dataResponse['userInfo']['lastName'].toString() }}
        </p>
        <b-list-group
          v-if="dataResponse != null"
          class="mb-2"
        >
          <b-list-group-item>
            {{ this.$t('OrderCode') }} :
            {{ subStringOrderId(dataResponse['orderCode']) }}
          </b-list-group-item>
          <b-list-group-item>
            {{ this.$t('OrderId') }} :
            {{ subStringOrderId(dataResponse['_id']).toUpperCase() }}
          </b-list-group-item>
        </b-list-group>
        <b-tab
          v-if="dataResponse != null"
          :title="$t('Detail')"
        >
          <b-table
            class="mb-0"
            :items="products"
            :fields="tableColumns"
            style="height: 60vh;"
          >
            <template #cell(product)="dataTable">
              <v-select
                id="respDataProduct"
                :value="valueData('product', dataTable.index)"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="name"
                :options="respDataProduct"
                :reduce="respDataProduct => respDataProduct"
                :filterable="false"
                class="select-size-lg"
                :clearable="false"
                @input="inputData($event, 'product', dataTable.index)"
                @search="onSearchProduct"
              >
                <div
                  slot="list-footer"
                  class="m-1"
                >
                  <b-row>
                    <b-col cols="4">
                      <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="info"
                        block
                        :disabled="currentPageProduct <= 1 ? true : false"
                        @click="nextPageProduct(currentPageProduct - 1)"
                      >
                        <feather-icon
                          icon="ChevronLeftIcon"
                          size="18"
                        />
                      </b-button>
                    </b-col>
                    <b-col cols="4">
                      <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="primary"
                        block
                        disabled
                      >
                        {{ currentPageProduct }}
                      </b-button>
                    </b-col>
                    <b-col cols="4">
                      <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="info"
                        block
                        :disabled="
                          currentPageProduct * perPageProduct >=
                            queriedItemsProduct
                            ? true
                            : false
                        "
                        @click="nextPageProduct(currentPageProduct + 1)"
                      >
                        <feather-icon
                          icon="ChevronRightIcon"
                          size="18"
                        />
                      </b-button>
                    </b-col>
                  </b-row>
                </div>
              </v-select>
            </template>
            <template #cell(amount)="dataTable">
              <cleave
                id="amount"
                :value="valueData('amount', dataTable.index)"
                class="form-control"
                :raw="false"
                :options="{
                  numeral: true,
                  numeralThousandsGroupStyle: 'thousand',
                }"
                placeholder="HH:MM"
                @input="inputData($event, 'amount', dataTable.index)"
              />
            </template>
            <template #cell(comment)="dataTable">
              <b-form-input
                id="comment"
                :placeholder="$t('Comment')"
                :value="valueData('comment', dataTable.index)"
                @input="inputData($event, 'comment', dataTable.index)"
              />
            </template>
            <template #cell(action)="dataTable">
              <b-dropdown
                variant="link"
                no-caret
                :right="$store.state.appConfig.isRTL"
              >
                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </template>
                <b-dropdown-item @click="deleteData(dataTable.index)">
                  <feather-icon icon="TrashIcon" />
                  <span class="align-middle ml-50">{{ $t('Delete') }}</span>
                </b-dropdown-item>
              </b-dropdown>
            </template>
          </b-table>
        </b-tab>
        <b-tab
          v-if="dataResponse != null"
          :title="$t('Time line')"
        >
          <timeline :data-response="dataResponse" />
        </b-tab>
      </b-tabs>
      <div
        v-if="dataResponse != null"
        class="float-right"
      >
        <b-row>
          <div
            v-if="dataResponse['orderStatus'] === 'to_be_shipped'"
            class="mr-2"
          >
            <v-select
              id="register-parcelDeliveryId"
              v-model="parcelDeliveryId"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="shortName"
              :options="parcelOption"
              :reduce="parcelOption => parcelOption._id"
              class="select-size-lg"
            />
          </div>
          <div
            v-if="dataResponse['orderStatus'] === 'to_be_shipped'"
            class="mr-2"
          >
            <b-form-input
              id="floating-label1"
              v-model="trackingCode"
              :placeholder="$t('Tracking Code')"
            />
          </div>
        </b-row>
      </div>
    </b-overlay>

    <template #modal-footer>
      <div class="w-100">
        <div
          v-if="dataResponse != null"
          class="float-right"
        >
          <b-row>
            <b-col cols="4">
              <b-card-text>{{ $t('Price') }} {{ price / 100 }}</b-card-text>
            </b-col>
            <b-col cols="8">
              <b-button
                v-if="
                  dataResponse['orderStatus'] === 'waiting_to_check_the_order'
                "
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="warning"
                size="sm"
                class="mr-2"
                @click="confirm"
              >
                {{ $t('Confirm') }}
                <feather-icon
                  icon="ShoppingBagIcon"
                  size="12"
                />
              </b-button>
            </b-col>
          </b-row>
        </div>
      </div>
    </template>
  </b-modal>
</template>

<script>
import {
  BModal,
  BRow,
  BTable,
  BCol,
  BTabs,
  BTab,
  BCardText,
  BFormCheckbox,
  BButton,
  BFormInput,
  BFormGroup,
  BOverlay,
  BListGroup,
  BListGroupItem,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'
import { required } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import Cleave from 'vue-cleave-component'
import perPageOptions from '@/perPageOptions'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import store from '@/store'
import DetailCheckOrder from './components/DetailCheckOrder.vue'
import Timeline from './components/Timeline.vue'

export default {
  components: {
    Cleave,
    // eslint-disable-next-line vue/no-unused-components
    vSelect,
    BModal,
    // eslint-disable-next-line vue/no-unused-components
    BRow,
    BTable,
    // eslint-disable-next-line vue/no-unused-components
    BCol,
    BTabs,
    BTab,
    // eslint-disable-next-line vue/no-unused-components
    BCardText,
    // eslint-disable-next-line vue/no-unused-components
    BFormCheckbox,
    // eslint-disable-next-line vue/no-unused-components
    BButton,
    BFormInput,
    // eslint-disable-next-line vue/no-unused-components
    BFormGroup,
    BOverlay,
    // eslint-disable-next-line vue/no-unused-components
    DetailCheckOrder,
    Timeline,
    BDropdown,
    BDropdownItem,
    // eslint-disable-next-line vue/no-unused-components
    BListGroup,
    // eslint-disable-next-line vue/no-unused-components
    BListGroupItem,
  },
  directives: {
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  model: {
    prop: 'isCheckOrderActive',
    event: 'update:is-check-order-active',
  },
  props: {
    storeModuleName: {
      type: String,
      required: true,
    },
    isCheckOrderActive: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Object,
      default: () => {},
    },
    show: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      dataId: null,
      dataResponse: null,
      dataLoading: false,
      products: [], // ตารางการจัดส่ง
      price: 0,
      searchProduct: '',
      currentPageProduct: 1,
      perPageProduct: 10,
      perPageOptions,
      required,
    }
  },
  computed: {
    respDataProduct() {
      return store.state[this.storeModuleName].respDataProduct != null
        ? store.state[this.storeModuleName].respDataProduct.data
        : []
    },
    queriedItemsProduct() {
      return store.state[this.storeModuleName].respDataProduct != null
        ? store.state[this.storeModuleName].respDataProduct.max
        : 0
    },
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    tableColumns() {
      return [
        {
          key: 'product',
          label: this.$t('Product'),
          sortable: false,
          thStyle: { width: '40%' },
        },
        { key: 'amount', label: this.$t('Amount'), sortable: false },
        { key: 'comment', label: this.$t('Comment'), sortable: false },
        { key: 'action', label: this.$t('Action'), sortable: false },
      ]
    },
  },
  watch: {
    isCheckOrderActive(val) {
      if (val) {
        if (Object.entries(this.data).length === 0) {
          //   this.$refs.formData.reset()
          this.initValues()
        } else {
          const { _id } = this.data
          this.dataId = _id
          this.viewData()
          this.onSearchProduct('')
        }
      }
    },
  },
  methods: {
    deleteData(index) {
      if (index > -1) {
        this.products.splice(index, 1)
      }
    },
    onSearchProduct(query) {
      this.searchProduct = query
      this.currentPageProduct = 1
      const obj = {
        currentPage: this.currentPageProduct,
        pageSize: this.perPageProduct,
        searchQuery: this.searchProduct,
      }
      store
        .dispatch(`${this.storeModuleName}/getProduct`, obj)
        .then(result => {
          // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
        })
        .catch(error => {
          console.log('fetchUsers Error : ', error)
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
          // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
        })
    },
    nextPageProduct(page) {
      this.currentPageProduct = page
      const obj = {
        currentPage: this.currentPageProduct,
        pageSize: this.perPageProduct,
        searchQuery: this.searchProduct,
      }
      store
        .dispatch(`${this.storeModuleName}/getProduct`, obj)
        .then(result => {
          // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
        })
        .catch(error => {
          console.log('fetchUsers Error : ', error)
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
          // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
        })
    },
    valueData(type, index) {
      if (type === 'product') {
        return this.products[index].product
      }
      if (type === 'amount') {
        return this.products[index].amount
      }
      if (type === 'comment') {
        return this.products[index].comment
      }
      return ''
    },
    inputData(data, type, index) {
      if (type === 'product') {
        this.products[index].product = data
        this.products[index].price = data.price * 100
        this.products[index].priceSum = parseFloat(data.price * 100) * parseFloat(this.products[index].amount)
        this.products[index].saleSum = parseFloat(data.price * 100) * parseFloat(this.products[index].amount)
        let price = 0
        for (let i = 0; i < this.products.length; i += 1) {
          price += this.products[i].saleSum
        }
        this.price = price
      }
      if (type === 'amount') {
        this.products[index].amount = data
        let price = 0
        for (let i = 0; i < this.products.length; i += 1) {
          this.products[i].priceSum = parseFloat(this.products[i].price)
            * parseFloat(this.products[i].amount)
          this.products[i].saleSum = parseFloat(this.products[i].price)
            * parseFloat(this.products[i].amount)
          price
            += parseFloat(this.products[i].price)
            * parseFloat(this.products[i].amount.replaceAll(',', ''))
        }
        this.price = price
      }
      if (type === 'comment') {
        this.products[index].comment = data
      }
    },
    initValues() {
      this.dataId = null
      this.dataResponse = null
      this.trackingCode = ''
      this.parcelDeliveryId = ''
    },
    subStringOrderId(data) {
      return data.substr(16, data.length)
    },
    showFromCurrentLanguage(data) {
      const indexLang = data.findIndex(e => e.lang === this.$i18n.locale)
      if (indexLang > -1) {
        return data[indexLang].value
      }
      return ''
    },
    viewData() {
      this.dataLoading = true
      store
        .dispatch(`${this.storeModuleName}/view`, { id: this.dataId })
        // eslint-disable-next-line no-unused-vars
        .then(result => {
          this.dataResponse = result.data.data
          this.products = this.dataResponse.products
          this.price = this.dataResponse.price

          this.dataLoading = false

          //
          // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
          // eslint-disable-next-line no-unused-vars
        })
        .catch(error => {
          this.dataLoading = false

          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
          // console.log('fetchUsers Error : ', error)
          // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
        })
    },
    confirm() {
      this.$bvModal
        .msgBoxConfirm(`${this.$t('Do you want to confirm')}?`, {
          title: this.$t('Please Confirm'),
          cancelVariant: 'outline-secondary',
          okVariant: 'success',
          okTitle: this.$t('Yes'),
          cancelTitle: this.$t('No'),
          centered: true,
        })
        // eslint-disable-next-line no-unused-vars
        .then(value => {
          if (value) {
            this.dataLoading = true
            store
              .dispatch(`${this.storeModuleName}/confirmCheckOrder`, {
                id: this.dataId,
                products: this.products,
              })
              // eslint-disable-next-line no-unused-vars
              .then(result => {
                this.dataResponse = result.data.data
                this.dataLoading = false
              })
              .catch(error => {
                this.dataLoading = false
                this.$toast({
                  component: ToastificationContent,
                  position: 'bottom-right',
                  props: {
                    title: this.$t('Error'),
                    icon: 'ErrorIcon',
                    variant: 'danger',
                    text: this.$t(error.response.data.message),
                  },
                })
                // console.log('fetchUsers Error : ', error)
                // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
              })
          }

          //  else {
          //   this.showToast(
          //     'warning',
          //     'top-right',
          //     `${this.$t('DELETE_CANCEL')}!`,
          //     'AlertCircleIcon',
          //     this.$t('DELETE_CANCEL'),
          //   )
          // }
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
