<template>
  <b-modal
    :visible="isModalActive"
    :title="$t('Shipped')"
    ok-title="Accept"
    size="xl"
    @change="(val) => $emit('update:is-modal-active', val)"
  >
    <b-overlay :show="dataLoading" rounded="sm">
      <b-tabs content-class="pt-1" fill>
        <p v-if="dataResponse != null" class="mr-2">
          {{ $t("Price to pay") }} :
          {{
            (dataResponse.currencyId === null
              ? dataResponse.sale / 100
              : (dataResponse.sale_currency / 100).toFixed(2))
              | numberWithCommas
          }}{{ dataResponse.currencySymbol }} ==> {{ $t("Payment Method") }} :
          {{ dataResponse["paymentMethod"].toString() | firstStringUpper }}
          {{
            dataResponse["paymentMethod"].toString() === "cash"
              ? `(${$t("Pay on delivery")})`
              : ""
          }}
        </p>
        <b-tab v-if="dataResponse != null" :title="$t('Detail')">
          <detail
            :data-response.sync="dataResponse"
            :store-module-name="storeModuleName"
          />
        </b-tab>
        <b-tab v-if="dataResponse != null" :title="$t('Slip')">
          <slip :data-response="dataResponse" />
        </b-tab>
        <b-tab v-if="dataResponse != null" :title="$t('Time line')">
          <timeline :data-response="dataResponse" />
        </b-tab>
      </b-tabs>
      <div v-if="dataResponse != null" class="float-right">
        <b-row>
          <div
            v-if="dataResponse['orderStatus'] === 'to_be_shipped'"
            class="mr-2"
          >
            <v-select
              id="register-parcelDeliveryId"
              v-model="parcelDeliveryId"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="shortName"
              :options="parcelOption"
              :reduce="(parcelOption) => parcelOption._id"
              class="select-size-lg"
            />
          </div>
          <div
            v-if="dataResponse['orderStatus'] === 'to_be_shipped'"
            class="mr-2"
          >
            <b-form-input
              id="floating-label1"
              v-model="trackingCode"
              :placeholder="$t('Tracking Code')"
            />
          </div>
        </b-row>
      </div>
    </b-overlay>

    <template #modal-footer>
      <div class="w-100">
        <div v-if="dataResponse != null" class="float-right">
          <b-button
            v-if="dataResponse['orderStatus'] === 'to_be_shipped'"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="warning"
            size="sm"
            class="mr-2"
            @click="delivery"
          >
            {{ $t("Shipped") }}
            <feather-icon icon="ShoppingBagIcon" size="12" />
          </b-button>
          <b-button
            v-if="
              dataResponse['orderStatus'] === 'pending_review' ||
              dataResponse['orderStatus'] === 'something_to_get'
            "
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="success"
            size="sm"
            class="mr-2"
            @click="success"
          >
            {{ $t("Success") }}
            <feather-icon icon="ShoppingBagIcon" size="12" />
          </b-button>
          <b-button
            v-if="
              dataResponse['orderStatus'] !== 'success' &&
              dataResponse['orderStatus'] !== 'cancel'
            "
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="danger"
            size="sm"
            class="mr-2"
            @click="cancelOrder"
          >
            {{ $t("Cancel order") }}
            <feather-icon icon="ShoppingBagIcon" size="12" />
          </b-button>
        </div>
      </div>
    </template>
  </b-modal>
</template>

<script>
import {
  BModal,
  BRow,
  BCol,
  BTabs,
  BTab,
  BCardText,
  BFormCheckbox,
  BButton,
  BFormInput,
  BFormGroup,
  BOverlay,
} from "bootstrap-vue";
import { required } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import store from "@/store";
import Detail from "./components/Detail.vue";
import Slip from "./components/Slip.vue";
import Timeline from "./components/Timeline.vue";

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    vSelect,
    BModal,
    // eslint-disable-next-line vue/no-unused-components
    BRow,
    // eslint-disable-next-line vue/no-unused-components
    BCol,
    BTabs,
    BTab,
    // eslint-disable-next-line vue/no-unused-components
    BCardText,
    // eslint-disable-next-line vue/no-unused-components
    BFormCheckbox,
    // eslint-disable-next-line vue/no-unused-components
    BButton,
    BFormInput,
    // eslint-disable-next-line vue/no-unused-components
    BFormGroup,
    BOverlay,
    // eslint-disable-next-line vue/no-unused-components
    Detail,
    Slip,
    Timeline,
  },
  directives: {
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  model: {
    prop: "isModalActive",
    event: "update:is-modal-active",
  },
  props: {
    storeModuleName: {
      type: String,
      required: true,
    },
    isModalActive: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Object,
      default: () => {},
    },
    show: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      dataId: null,
      dataResponse: null,
      dataLoading: false,
      trackingCode: "",
      parcelDeliveryId: "",
      parcelOption: [],
      required,
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
  },
  watch: {
    isModalActive(val) {
      this.getParcelDelivery();
      if (val) {
        if (Object.entries(this.data).length === 0) {
          // this.$refs.formData.reset()
          this.initValues();
        } else {
          const { _id } = this.data;
          this.dataId = _id;
          this.viewData();
        }
      }
    },
  },
  methods: {
    closeModal() {
      this.$emit("update:is-modal-active", false);
    },
    initValues() {
      this.dataId = null;
      this.dataResponse = null;
      this.trackingCode = "";
      this.parcelDeliveryId = "";
    },
    showFromCurrentLanguage(data) {
      const indexLang = data.findIndex((e) => e.lang === this.$i18n.locale);
      if (indexLang > -1) {
        return data[indexLang].value;
      }
      return "";
    },
    getParcelDelivery() {
      store
        .dispatch(`${this.storeModuleName}/parcelDelivery`, {})
        // eslint-disable-next-line no-unused-vars
        .then((result) => {
          this.parcelOption = result.data.data;

          //
          // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
          // eslint-disable-next-line no-unused-vars
        })
        .catch((error) => {
          this.dataLoading = false;

          this.$toast({
            component: ToastificationContent,
            position: "bottom-right",
            props: {
              title: this.$t("Error"),
              icon: "ErrorIcon",
              variant: "danger",
              text: this.$t(error.response.data.message),
            },
          });
          // console.log('fetchUsers Error : ', error)
          // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
        });
    },
    viewData() {
      this.dataLoading = true;
      store
        .dispatch(`${this.storeModuleName}/view`, { id: this.dataId })
        // eslint-disable-next-line no-unused-vars
        .then((result) => {
          this.dataResponse = result.data.data;
          this.dataLoading = false;

          //
          // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
          // eslint-disable-next-line no-unused-vars
        })
        .catch((error) => {
          this.dataLoading = false;

          this.$toast({
            component: ToastificationContent,
            position: "bottom-right",
            props: {
              title: this.$t("Error"),
              icon: "ErrorIcon",
              variant: "danger",
              text: this.$t(error.response.data.message),
            },
          });
          // console.log('fetchUsers Error : ', error)
          // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
        });
    },
    cancelOrder() {
      this.$bvModal
        .msgBoxConfirm(`${this.$t("Do you want to cancel")}?`, {
          title: this.$t("Please Confirm"),
          cancelVariant: "outline-secondary",
          okVariant: "danger",
          okTitle: this.$t("Yes"),
          cancelTitle: this.$t("No"),
          centered: true,
        })
        // eslint-disable-next-line no-unused-vars
        .then((value) => {
          if (value) {
            this.dataLoading = true;
            store
              .dispatch(`${this.storeModuleName}/cancelOrder`, {
                id: this.dataId,
              })
              // eslint-disable-next-line no-unused-vars
              .then((result) => {
                this.dataResponse = result.data.data;
                this.dataLoading = false;
              })
              .catch((error) => {
                this.dataLoading = false;
                this.$toast({
                  component: ToastificationContent,
                  position: "bottom-right",
                  props: {
                    title: this.$t("Error"),
                    icon: "ErrorIcon",
                    variant: "danger",
                    text: this.$t(error.response.data.message),
                  },
                });
                // console.log('fetchUsers Error : ', error)
                // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
              });
          }

          //  else {
          //   this.showToast(
          //     'warning',
          //     'top-right',
          //     `${this.$t('DELETE_CANCEL')}!`,
          //     'AlertCircleIcon',
          //     this.$t('DELETE_CANCEL'),
          //   )
          // }
        });
    },
    delivery() {
      if (this.trackingCode.length > 0 && this.parcelDeliveryId.length > 0) {
        this.$bvModal
          .msgBoxConfirm(`${this.$t("Do you want to delivery")}?`, {
            title: this.$t("Please Confirm"),
            cancelVariant: "outline-secondary",
            okVariant: "success",
            okTitle: this.$t("Yes"),
            cancelTitle: this.$t("No"),
            centered: true,
          })
          // eslint-disable-next-line no-unused-vars
          .then((value) => {
            if (value) {
              this.dataLoading = true;
              store
                .dispatch(`${this.storeModuleName}/delivery`, {
                  id: this.dataId,
                  trackingCode: this.trackingCode,
                  parcelDeliveryId: this.parcelDeliveryId,
                })
                // eslint-disable-next-line no-unused-vars
                .then((result) => {
                  this.dataResponse = result.data.data;
                  this.dataLoading = false;
                })
                .catch((error) => {
                  this.dataLoading = false;
                  this.$toast({
                    component: ToastificationContent,
                    position: "bottom-right",
                    props: {
                      title: this.$t("Error"),
                      icon: "ErrorIcon",
                      variant: "danger",
                      text: this.$t(error.response.data.message),
                    },
                  });
                  // console.log('fetchUsers Error : ', error)
                  // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
                });
            }

            //  else {
            //   this.showToast(
            //     'warning',
            //     'top-right',
            //     `${this.$t('DELETE_CANCEL')}!`,
            //     'AlertCircleIcon',
            //     this.$t('DELETE_CANCEL'),
            //   )
            // }
          });
      } else {
        this.$toast({
          component: ToastificationContent,
          position: "bottom-right",
          props: {
            title: this.$t("Error"),
            icon: "ErrorIcon",
            variant: "danger",
            text: this.$t("Please enter tracking code or parcel delivery"),
          },
        });
      }
    },
    success() {
      this.$bvModal
        .msgBoxConfirm(`${this.$t("Do you want to success")}?`, {
          title: this.$t("Please Confirm"),
          cancelVariant: "outline-secondary",
          okVariant: "success",
          okTitle: this.$t("Yes"),
          cancelTitle: this.$t("No"),
          centered: true,
        })
        // eslint-disable-next-line no-unused-vars
        .then((value) => {
          if (value) {
            this.dataLoading = true;
            store
              .dispatch(`${this.storeModuleName}/success`, {
                id: this.dataId,
              })
              // eslint-disable-next-line no-unused-vars
              .then((result) => {
                this.dataResponse = result.data.data;
                this.dataLoading = false;
              })
              .catch((error) => {
                this.dataLoading = false;
                this.$toast({
                  component: ToastificationContent,
                  position: "bottom-right",
                  props: {
                    title: this.$t("Error"),
                    icon: "ErrorIcon",
                    variant: "danger",
                    text: this.$t(error.response.data.message),
                  },
                });
                // console.log('fetchUsers Error : ', error)
                // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
              });
          }

          //  else {
          //   this.showToast(
          //     'warning',
          //     'top-right',
          //     `${this.$t('DELETE_CANCEL')}!`,
          //     'AlertCircleIcon',
          //     this.$t('DELETE_CANCEL'),
          //   )
          // }
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
