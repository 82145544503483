<template>
  <b-modal :visible="isLinkPaymentModalActive" :title="$t('Link Payment')" ok-title="Accept" size="lg"
    @change="(val) => $emit('update:is-link-payment-modal-active', val)">
    <b-overlay :show="dataLoading" rounded="sm">
      <div v-if="dataResponse != null" class="w-100">
        <div class="w-100">
          <b-form-input class="w-100" v-model="linkPayment" :placeholder="$t('Link Payment')" />
        </div>
      </div>
    </b-overlay>

    <template #modal-footer>
      <div class="w-100">
        <div v-if="dataResponse != null" class="float-right">
          <b-button @click="saveLinkPayment" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="success">
            {{ $t('Save') }}
            <feather-icon icon="SaveIcon" size="12" />
          </b-button>
        </div>
      </div>
    </template>
  </b-modal>
</template>

<script>

import {
  BModal,
  BRow,
  BCol,
  BCardText,
  BFormCheckbox,
  BButton,
  BFormInput,
  BFormGroup,
  BOverlay,

} from 'bootstrap-vue'
import { required } from '@validations'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import store from '@/store'

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    vSelect,
    BModal,
    // eslint-disable-next-line vue/no-unused-components
    BRow,
    // eslint-disable-next-line vue/no-unused-components
    BCol,
    // eslint-disable-next-line vue/no-unused-components
    BCardText,
    // eslint-disable-next-line vue/no-unused-components
    BFormCheckbox,
    // eslint-disable-next-line vue/no-unused-components
    BButton,
    BFormInput,
    // eslint-disable-next-line vue/no-unused-components
    BFormGroup,
    BOverlay,
  },
  directives: {
    Ripple,
  },
  mixins: [],
  model: {
    prop: 'isLinkPaymentModalActive',
    event: 'update:is-link-payment-modal-active',
  },
  props: {
    storeModuleName: {
      type: String,
      required: true,
    },
    isLinkPaymentModalActive: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Object,
      default: () => { },
    },
    show: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      dataId: null,
      dataResponse: null,
      dataLoading: false,
      linkPayment: '',
      required,
    }
  },
  computed: {},
  watch: {
    isLinkPaymentModalActive(val) {
      if (val) {
        if (Object.entries(this.data).length === 0) {
          // this.$refs.formData.reset()
          this.initValues()
        } else {
          const {
            _id,
          } = this.data
          this.dataId = _id
          this.viewData()
        }
      }
    },
  },
  methods: {
    initValues() {
      this.dataId = null
      this.dataResponse = null
      this.linkPayment = ''
    },
    showFromCurrentLanguage(data) {
      const indexLang = data.findIndex(e => e.lang === this.$i18n.locale)
      if (indexLang > -1) {
        return data[indexLang].value
      }
      return ''
    },
    viewData() {
      this.dataLoading = true
      store
        .dispatch(`${this.storeModuleName}/view`, { id: this.dataId })
        // eslint-disable-next-line no-unused-vars
        .then(result => {
          this.dataResponse = result.data.data
          this.dataLoading = false
          this.linkPayment = this.dataResponse.linkPayment
        }).catch(error => {
          this.dataLoading = false

          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
          // console.log('fetchUsers Error : ', error)
          // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
        })
    },
    saveLinkPayment() {
      if (this.linkPayment.length > 0) {
        this.$bvModal
          .msgBoxConfirm(`${this.$t('Do you want to save link payment')}?`, {
            title: this.$t('Please Confirm'),
            cancelVariant: 'outline-secondary',
            okVariant: 'success',
            okTitle: this.$t('Yes'),
            cancelTitle: this.$t('No'),
            centered: true,
          })
          // eslint-disable-next-line no-unused-vars
          .then(value => {
            if (value) {
              this.dataLoading = true
              store
                .dispatch(`${this.storeModuleName}/saveLinkPayment`, { id: this.dataId, linkPayment: this.linkPayment })
                // eslint-disable-next-line no-unused-vars
                .then(result => {
                  this.dataResponse = result.data.data
                  this.dataLoading = false
                }).catch(error => {
                  this.dataLoading = false
                  this.$toast({
                    component: ToastificationContent,
                    position: 'bottom-right',
                    props: {
                      title: this.$t('Error'),
                      icon: 'ErrorIcon',
                      variant: 'danger',
                      text: this.$t(error.response.data.message),
                    },
                  })
                })
            }
          })
      } else {
        this.$toast({
          component: ToastificationContent,
          position: 'bottom-right',
          props: {
            title: this.$t('Error'),
            icon: 'ErrorIcon',
            variant: 'danger',
            text: this.$t('Please enter input link payment'),
          },
        })
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
