<template>
  <div v-if="dataResponse != null">
    <b-table
      ref="refUserListTable"
      striped
      responsive
      class="position-relative"
      :items="dataResponse.products"
      :fields="tableColumns"
      primary-key="_id"
      show-empty
      :empty-text="$t('No matching records found')"
      style="height: 60vh;"
    >
      <template #cell(_id)="data">
        <b-card-text>{{ data.index + 1 }}</b-card-text>
      </template>
      <template #cell(product)="data">
        <b-card-text>{{ data.item.product.name }}</b-card-text>
      </template>
    </b-table>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCardText,
  BFormCheckbox,
  BButton,
  BImg,
  BMedia,
  BTable,
  BListGroup,
  BListGroupItem,
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    vSelect,
    // eslint-disable-next-line vue/no-unused-components
    BRow,
    // eslint-disable-next-line vue/no-unused-components
    BCol,
    // eslint-disable-next-line vue/no-unused-components
    BCardText,
    // eslint-disable-next-line vue/no-unused-components
    BFormCheckbox,
    // eslint-disable-next-line vue/no-unused-components
    BButton,
    // eslint-disable-next-line vue/no-unused-components
    BImg,
    // eslint-disable-next-line vue/no-unused-components
    BMedia,
    BTable,
    // eslint-disable-next-line vue/no-unused-components
    BListGroup,
    // eslint-disable-next-line vue/no-unused-components
    BListGroupItem,
  },
  directives: {
    Ripple,
  },
  props: {
    dataResponse: {
      type: Object,
      required: true,
    },
  },
  computed: {
    tableColumns() {
      return [
        { key: '_id', label: '#', sortable: false },
        { key: 'product', label: this.$t('Product name'), sortable: false },
        { key: 'amount', label: this.$t('Amount'), sortable: false },
      ]
    },
  },
  methods: {
    subStringOrderId(data) {
      return data.substr(16, data.length)
    },
    showFromCurrentLanguage(data) {
      const indexLang = data.findIndex(e => e.lang === this.$i18n.locale)
      if (indexLang > -1) {
        return data[indexLang].value
      }
      return ''
    },
  },
}
</script>
